
class ResponsiveScenes {
  constructor() {
    this.responsiveScenes = [];
    this.addResizeListener();
  }

  addScene(scene, size, cb, pin) {
    if (size.matches) {
      scene.enabled(false);
      scene.removePin(true);
      cb();
    }

    this.responsiveScenes.push({
      scene,
      size,
      cb,
      pin
    });
  }

  addResizeListener() {
    window.addEventListener('resize', this.handleScenesResize.bind(this));
  }

  handleScenesResize() {
    this.responsiveScenes.forEach(sceneObj => {
      const isEnabled = sceneObj.scene.enabled()
      sceneObj.scene.enabled(!sceneObj.size.matches);
      if (sceneObj.size.matches && isEnabled) {
        sceneObj.scene.removePin(true);
        sceneObj.cb();
      }

      if (!sceneObj.size.matches && !isEnabled && sceneObj.pin) {
        sceneObj.scene.setPin(sceneObj.pin);

        window.scrollTo(0, window.scrollY + 1);
      }
    });
  }
}

const responsiveScenes = new ResponsiveScenes;
export default responsiveScenes;