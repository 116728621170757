const sortingControls = (sortingContainer) => {
    const activeFieldClass = 'is-checked';
    const fields = sortingContainer.querySelectorAll('.a-sorting__field');
    const checkboxes = sortingContainer.querySelectorAll('input.a-sorting__field-label--inp');
    const mobileDropdown = sortingContainer.querySelector('.a-sorting__mobile--dropdown');
    let activeField;
    let activeInp;

    const setActive = function(inp){
        const changedField = inp.closest('.a-sorting__field');

        if(!activeInp || activeInp.checked !== true || !changedField && !changedField.classList.contains(activeFieldClass)) {
            if(activeField) {
                activeField.classList.remove(activeFieldClass);
            }
            activeInp = inp;
            changedField.classList.add(activeFieldClass);
            activeField = changedField;
        }
        const activeGroup = activeInp.getAttribute('data-group');
        if(mobileDropdown.value !== activeGroup ){
            mobileDropdown.value = activeGroup;
        }

    };


    if(fields.length && checkboxes.length) {

        checkboxes[0].checked = true;
        setActive(checkboxes[0]);

        [].forEach.call(checkboxes, function(inp) {
            inp.addEventListener('change', () => {
                setActive(inp);
            });
        });

        mobileDropdown.addEventListener('change', () => {
            let changedInp = [].filter.call(checkboxes, (inp) => { return inp.value === mobileDropdown.value + '_up' })[0];
            changedInp.checked = true;
            setActive(changedInp);
        });
    }

};

export default sortingControls;

// document.addEventListener('DOMContentLoaded', () => {
//     const sortingContainer = document.querySelector('.js-sorting');
//     if(sortingContainer){
//         sortingControls(sortingContainer);
//     }
// });