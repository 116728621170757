class Validation {
  constructor(form) {
    this.form = form;
    this.inputs = form.querySelectorAll('input');
    this.submits = form.querySelectorAll('button[type="submit"]');
    this.minLength = 2;
    this.errorMsg = {
      min: 'Minimum number or characters not met',
      required: 'This field is required'
    }
  }

  validateOnBlur(input) {
    if(input.value.length < this.minLength) {
      input.parentNode.querySelector('.m-input_msg--error').textContent = (input.value.length === 0)
        ? this.errorMsg.required
        : this.errorMsg.min
      input.classList.add('m-input--error');
    } else {
      if(input.classList.contains('m-input--error')) {
        input.classList.remove('m-input--error');
        input.parentNode.querySelector('.m-input_msg--error').textContent = '';
      }
    }
  }

  validateOnSubmit(button, e) {
    e.preventDefault();
    const form = this.form;
    const errors = form.querySelectorAll('.m-input_msg--error');

    const dropdown = form.querySelector('.m-form__form-field--dropdown');
    dropdown.classList.add('m-input_msg');
    dropdown.parentNode.querySelector('.m-input_msg--error').textContent = 'Example of error on form submit.';

    [...errors].forEach(error => {
      // show toast
      if(error.textContent.length > 0) {
        form.querySelector('.m-toast').classList.add('m-toast--error');
        console.log('error: ', error);
      }
    })
  }

  init() {
    const requiredInputs = [...this.inputs].filter(e => e.hasAttribute('required'));

    [...requiredInputs].forEach(input => input.addEventListener('blur', () => this.validateOnBlur(input)));

    [...this.submits].forEach(button => {

      button.addEventListener('click', (e) => {
        this.validateOnSubmit(button, e)
      })
    }
    );
  }
}

// UNCOMMENT TO ADD BASIC
// CLIENT-SIDE VALIDATION

// window.addEventListener('load', () => {
//   const forms = document.querySelectorAll('form');
//   [...forms].forEach(form => {
//     const v = new Validation(form);
//     v.init();
//   });
// });