import './partials/ie-polifylls';
import anime from 'animejs/lib/anime.es';
import ScrollMagic from 'ScrollMagic';
import 'waypoints/lib/noframework.waypoints.min.js';
import objectFitImages from 'object-fit-images';
import objectFitVideos from 'object-fit-videos';
import svg4everybody from 'svg4everybody';
import responsiveScenes from './partials/hpPartials/hpResize';
import Bowser from "bowser";

if (Bowser.name === "Internet Explorer") {
  customEventPolyfill();
}

import './partials/sorting';
import './partials/dropDown';
import './partials/validation';
import toast from './partials/toast';
import sortingControls from './partials/sorting';

// properties
let navigation = document.getElementsByClassName('a-nav')[0];
let bannerEl = document.getElementsByClassName('m-banner')[0] || document.getElementsByClassName('m-hero')[0] || document.getElementsByClassName('m-transactions-hero')[0];
let miniMenuStartPos = document.getElementsByClassName('m-choice')[0] || document.getElementsByClassName('m-find-an-sior')[0] || document.getElementsByClassName('m-article')[0] || document.getElementsByClassName('m-article-detail__content')[0] || document.getElementsByClassName('js-menuStartPos')[0];
let logoContainer = document.getElementsByClassName('a-nav__logo')[0];
let sectionTransaction = document.getElementsByClassName('m-transaction')[0];
let miniMenu = false;
let mainMenuText = document.querySelector('.a-nav__mobile--text');
let mainMenuIcon = document.querySelector('.a-nav__mobile--icon');
let mainNavBtnContainer = document.querySelector('.a-nav__mobile--right');
let isMainMenuOpen = false;
let isSecondaryMenuOpen = false;
let winWidth = window.innerWidth;
let isHomepage = navigation.classList.contains('a-nav--homepage');
let navBarBg = navigation.getElementsByClassName('a-nav--light-bg')[0];

// variables in css
let navHeightMobile = '60';
let navLogoTopMobile = '30';
let menuBreakPoint = '1350';
let menuSecondaryBreakPoint = '1200';

// breakpoints
let matchMediaBelowSmall = window.matchMedia('(max-width: 550px)');
let matchMediaBelowMedium = window.matchMedia('(max-width: 650px)');
let matchMediaBelowMediumLarge = window.matchMedia('(max-width: 767px)');
let matchMediaBelowLarge = window.matchMedia(`(max-width: ${menuBreakPoint}px)`);
let matchMediaBelowMenuSecondary = window.matchMedia(`(max-width: ${menuSecondaryBreakPoint}px)`);
let matchMediaBelowXLarge = window.matchMedia(`(max-width: 1350px)`);

// show / hide main menu
function toggleMainMenu() {
  let mainNavBtn = mainNavBtnContainer.querySelector('.a-nav__mobile--icon');
  let hasToast = document.querySelector('.m-toast');

  mainNavBtn.addEventListener('click', () => {
    // menu already open
    if (isMainMenuOpen) {
      mainMenuText.textContent = 'menu';

      if (hasToast) {
        mainNavBtnContainer.removeAttribute('style');
      }

      // reset menu items
      if (matchMediaBelowMedium.matches) {
        mainMenuText.style.display = 'block';
      } else {
        mainMenuText.textContent = 'menu';
      }

      // remove border to icon container
      mainNavBtnContainer.style.backgroundColor = 'rgba(236, 242, 247, .5)';
      document.body.style.overflow = 'auto';

      // animate menu bg and items
      anime({
        targets: '.menu-open-vjs',
        opacity: 0,
        duration: 200,
        easing: 'easeOutQuad',
        complete: () => {
          anime.set('.menu-open-vjs', {
            scale: 0
          });
        }
      });

      // animate menu icon
      anime({
        targets: mainMenuIcon.children[0],
        rotate: 0,
        top: matchMediaBelowXLarge.matches ? 15 : 16,
        left: 0,
        duration: 200,
        easing: 'easeOutSine'
      });
      anime({
        targets: mainMenuIcon.children[2],
        rotate: 0,
        bottom: matchMediaBelowXLarge.matches ? 14 : 16,
        left: 0,
        duration: 200,
        easing: 'easeOutSine'
      });
      anime({
        targets: mainMenuIcon.children[1],
        translateX: 0,
        opacity: 1,
        duration: 300,
        easing: 'easeOutSine'
      });

      // reset menu items
      if (!matchMediaBelowMedium.matches) {
        if (getComputedStyle(navBarBg).opacity == 0) {
          mainMenuText.style.color = '#4b596d';
          // navigation.querySelector('.a-nav__mobile--right').style.backgroundColor = 'transparent';
        }
      } else {
        // add border to icon container on smaller devices
        mainNavBtnContainer.style.border = 'none';
      }
    } else {
      matchMediaBelowMedium = window.matchMedia('(max-width: 650px)');

      if (hasToast) {
        mainNavBtnContainer.style.position = 'fixed';
        mainNavBtnContainer.style.top = 0;
        mainNavBtnContainer.style.right = 0;
      }

      anime.set('.menu-open-vjs', { opacity: 1 });

      // change menu container to match bg color
      if (matchMediaBelowMedium.matches) mainNavBtnContainer.style.backgroundColor = '#30384d';
      let matchMediaBelowMedium = window.matchMedia('(max-width: 767px)');

      // animate menu bg and items
      anime({
        targets: '.menu-open-vjs',
        scale: [0, 1],
        duration: 400,
        easing: 'easeOutQuad'
      });

      anime({
        targets: '.a-nav__mobile-search',
        opacity: [0, 1],
        duration: 1000,
        easing: 'easeOutQuad'
      });

      // animate menu icon
      anime({
        targets: mainMenuIcon.children[1],
        opacity: [0, 1],
        opacity: 0,
        duration: 200,
        easing: 'easeOutSine',
        complete: () => {
          if (matchMediaBelowMedium.matches) {
            let matchMediaBelowMedium = window.matchMedia('(max-width: 767px)');
            // add border to icon container on smaller devices
            mainNavBtnContainer.style.border = '1px solid';
            document.body.style.overflow = 'hidden';
          }

          anime({
            targets: mainMenuIcon.children[0],
            rotate: 45,
            top: matchMediaBelowXLarge.matches ? 15 : 16,
            left: 6,
            duration: 200,
            easing: 'easeOutSine'
          });
          anime({
            targets: mainMenuIcon.children[2],
            rotate: -45,
            bottom: matchMediaBelowXLarge.matches ? 17 : 16,
            left: 5,
            duration: 200,
            easing: 'easeOutSine'
          });
        }
      });

      // reset menu items
      if (matchMediaBelowMedium.matches) {
        mainMenuText.style.display = 'none';
      } else {
        if (getComputedStyle(navBarBg).opacity == 0) {
          mainMenuText.style.color = '#4b596d';
        }
        mainMenuText.textContent = 'close';
      }
    }

    isMainMenuOpen = !isMainMenuOpen;
  });
}

// show / hide main sub menu
function openMainSubMenu() {
  let els = document.querySelectorAll('.a-nav__link--subnav-js');
  let closes = document.querySelectorAll('.a-nav__link--subnav-close');
  let lists = document.querySelectorAll('.a-nav__link--subnav-container');
  let navWidth = document.querySelector('.a-nav__list').getBoundingClientRect().width;
  let activePage = document.querySelector('.a-nav__link--active');
  let isOpen = false;
  let height;
  let openEl;

  [...els].forEach(el => {
    el.addEventListener('click', function(e) {

      if(!matchMediaBelowLarge.matches) {

        // on desktop and wider
        // dont follow <a> link
        e.preventDefault();

        navWidth = document.querySelector('.a-nav__list').getBoundingClientRect().width;

        if(openEl) {
          openEl.classList.remove('a-nav__link--active')
          openEl.parentNode.classList.remove('a-nav__link--subnav--inner-active');
          openEl.parentNode.querySelector('.a-nav__link--subnav-close').style.display = 'none';
          openEl.parentNode.querySelector('.a-nav__link--subnav-list').style.display = 'none';
          openEl.nextElementSibling.style.height = 0;
        }
        openEl = this;

        const container = openEl.parentNode.querySelector('.a-nav__link--subnav--inner');
        container.classList.add('a-nav__link--subnav--inner-active');

        // give temporary active status to main nav item
        openEl.classList.add('a-nav__link--active');
        // remove active status on main nav item (while sub nav it open at least)
        if(openEl == activePage) {
          activePage.classList.add('a-nav__link--active');
        } else {
          if(activePage) {
            activePage.classList.remove('a-nav__link--active');
          }
        }

        let containerChildren = container.querySelectorAll('.a-nav__link--subnav-container')
        let childMaxWidth = window.getComputedStyle(containerChildren[0]).getPropertyValue('max-width');
        let isWiderThanNav = (parseInt(childMaxWidth) * containerChildren.length) > navWidth ? true : false;

        // console.log('width: ', navWidth);
        // console.log('children in sub: ', container.querySelectorAll('.a-nav__link--subnav-container').length);
        // console.log('max-width: ', window.getComputedStyle(container.querySelectorAll('.a-nav__link--subnav-container')[0]).getPropertyValue('max-width'));
        // console.log('isWiderThanNav: ', isWiderThanNav);

        if(!isWiderThanNav) {
          let newWidth = (navWidth/containerChildren.length) + (60/containerChildren.length);

          [...containerChildren].map(child => {
            // child.style.width = `${newWidth}px`
            child.style.maxWidth = '100%';
            child.querySelector('a').style.alignItems = 'flex-start';
          });
          container.querySelector('.a-nav__link--subnav-list').style.width = `${navWidth + 10}px`;
          container.querySelector('.a-nav__link--subnav-list').style.justifyContent = 'flex-start'
          container.querySelector('.a-nav__link--subnav-list').style.marginLeft = 'auto';
        }

        container.querySelector('.a-nav__link--subnav-list').style.display = 'flex';
        height = parseInt(window.getComputedStyle(openEl.parentNode.querySelector('.a-nav__link--subnav--inner')).getPropertyValue('height'));
        container.querySelector('.a-nav__link--subnav-list').style.display = 'none';

        setTimeout(() => {
          anime({
            targets: openEl.parentNode.querySelector('.a-nav__link--subnav'),
            height: [0, height],
            opacity: {
              value: [0, 1],
              duration: 500
            },
            // duration: 5000,
            duration: 200,
            easing: 'easeOutSine',
            complete: () => {
              openEl.parentNode.querySelector('.a-nav__link--subnav-list').style.display = 'flex';
              openEl.parentNode.querySelector('.a-nav__link--subnav-close').style.display = 'block';
              isOpen = true;
            }
          });
        }, 0);
      }
    });
  });


  [...closes].forEach(close => {
    close.addEventListener('click', function() {

      if(!matchMediaBelowLarge.matches) {

        // remove temporary active status from main nav item
        openEl.parentNode.querySelector('.a-nav__link').classList.remove('a-nav__link--active');
        // re-add active status on main nav item (while sub nav it open at least)
        if (activePage) {
          activePage.classList.add('a-nav__link--active');
        }

        anime({
          targets: openEl.nextElementSibling,
          height: [height, 0],
          opacity: [1, 0],
          duration: 150,
          easing: 'linear'
        })

        openEl.parentNode.classList.remove('a-nav__link--subnav--inner-active');
        openEl.parentNode.querySelector('.a-nav__link--subnav-close').style.display = 'none';
        openEl.parentNode.querySelector('.a-nav__link--subnav-list').style.display = 'none';
        isOpen = false;
      }
    });
  });

  window.addEventListener('resize', () => {


    if(!matchMediaBelowLarge.matches) {

      if(openEl) {
        openEl.parentNode.classList.remove('a-nav__link--subnav--inner-active');
        openEl.parentNode.querySelector('.a-nav__link--subnav-close').style.display = 'none';
        openEl.parentNode.querySelector('.a-nav__link--subnav-list').style.display = 'none';
        openEl.nextElementSibling.style.height = 0;
      }
      isOpen = false;
    }
  });
}


// show / hide secondary menu (not on every page)
function toggleSecondarySubMenu() {
  const secondaryNavList = document.querySelector('.a-sidebar__sidebar-nav');
  const listItemLi = document.querySelectorAll('.a-sidebar__sidebar-item--parent');
  const listItemUl = document.querySelectorAll('.a-sidebar__sidebar-list');
  let debugSecondarySubMenu = {};

  [...listItemLi].forEach(el => {
    // listen for list item click
    el.addEventListener('click', function(element) {
      let prevSubListHeight;
      let secondaryNavListHeight = parseInt(window.getComputedStyle(secondaryNavList).getPropertyValue('height'));
      let currentSubListHeight = parseInt(window.getComputedStyle(el.querySelector('.a-sidebar__sidebar-sublist--js')).getPropertyValue('height'));

      // loop list items again
      [...listItemLi].forEach(el => {
        // remove styles on other items
        if (el !== this) {
          prevSubListHeight = parseInt(window.getComputedStyle(el.querySelector('.a-sidebar__sidebar-sublist--js')).getPropertyValue('height'));
          el.classList.remove('is-expand');
          el.firstElementChild.classList.remove('a-sidebar__sidebar-link--active');
          el.firstElementChild.nextElementSibling.style.height = 0;
        }
      });

      // if clicked items not already expanded
      if (this.classList.contains('is-expand')) {
        this.classList.remove('is-expand');
        this.firstElementChild.classList.remove('a-sidebar__sidebar-link--active');

        // control height of overall container:
        // this gets called if you click
        // a list that's already open
        anime({
          targets: secondaryNavList,
          height: [secondaryNavListHeight, secondaryNavListHeight - currentSubListHeight],
          duration: 150,
          easing: 'linear',
          complete: () => {
            this.firstElementChild.nextElementSibling.style.display = 'none';
          }
        });

        // debug
        debugSecondarySubMenu.open = false;
        debugSecondarySubMenu.asidebar__sidebarsublistjs = el.querySelector('.a-sidebar__sidebar-sublist--js');
        debugSecondarySubMenu.currentSubListHeight = currentSubListHeight;

        // control height of sublist
        anime({
          targets: el.querySelector('.a-sidebar__sidebar-sublist--js'),
          opacity: [0, 1],
          height: [currentSubListHeight, 0],
          duration: 150,
          easing: 'linear',
          complete: () => {
            this.firstElementChild.nextElementSibling.style.display = 'none';
          }
        });

        console.log('debugSecondarySubMenu: ', debugSecondarySubMenu);
      } else {
        // expand and give styles
        this.classList.add('is-expand');
        this.firstElementChild.classList.add('a-sidebar__sidebar-link--active');

        // anime opening:
        // step - set to block and opacity 0
        this.firstElementChild.nextElementSibling.style.display = 'block';
        this.firstElementChild.nextElementSibling.style.opacity = 0;

        // debug
        debugSecondarySubMenu.open = true;
        debugSecondarySubMenu.asidebar__sidebarsublistjs = el.querySelector('.a-sidebar__sidebar-sublist--js');
        debugSecondarySubMenu.currentSubListHeight = currentSubListHeight;
        debugSecondarySubMenu.firstElementChildNextElementSiblingFirstElementChild = this.firstElementChild.nextElementSibling.firstElementChild;

        // step - grab height of `.a-sidebar__sidebar-sublist` element
        let isOpeningHeight = parseInt(getComputedStyle(this.firstElementChild.nextElementSibling.firstElementChild).getPropertyValue('height'));
        let newHeight, parentContainerHeight;

        // debug
        debugSecondarySubMenu.isOpeningHeight = isOpeningHeight;

        // know whether to add or subtract
        // from the parent container
        if (isOpeningHeight < prevSubListHeight) {
          parentContainerHeight = prevSubListHeight - isOpeningHeight;
          newHeight = secondaryNavListHeight - parentContainerHeight;
        } else {
          parentContainerHeight = isOpeningHeight - prevSubListHeight;
          newHeight = secondaryNavListHeight + parentContainerHeight;
        }


        debugSecondarySubMenu.secondaryNavListHeight = secondaryNavListHeight;
        debugSecondarySubMenu.newHeight = newHeight;

        // control height of overall container:
        // this gets called if you click a
        // list that's NOT currently open
        anime({
          targets: secondaryNavList,
          height: [secondaryNavListHeight, newHeight],
          duration: 150,
          easing: 'linear'
        });

        // step height of `.a-sidebar__sidebar-sublist--js` to 0
        anime({
          targets: this.firstElementChild.nextElementSibling,
          opacity: {
            value: [0, 1],
            duration: 200
          },
          height: {
            value: [0, isOpeningHeight],
            duration: 150
          },
          easing: 'linear'
        });

        console.log('debugSecondarySubMenu: ', debugSecondarySubMenu);
      }
    });
  });
}

// toggle the background state of active filter buttons
function toggleFilterButtons() {
    const filterContainer = document.querySelectorAll('.filter-buttons-js');

    // add event listener to btns
    [...filterContainer].forEach(container => {
      const btns = container.querySelectorAll('button');
      [...btns].forEach(btn => {
        // on click, call fn to add / remove active class
        btn.addEventListener('click', () => {toggleFilter(btn)});
      });
    });

    // run through buttons in container
    // add active, remove other actives
    function toggleFilter (btn) {
      [...btn.parentNode.querySelectorAll('button')].forEach(b => {
        if(b === btn) {
          btn.classList.add('v-button--active');
        } else if(b.classList.contains('v-button--active')) {
          b.classList.remove('v-button--active')
        }
      });

    }
}

function toggleSecondaryMenu() {
  let secondaryNavBtn = document.querySelector('.a-sidebar__mobile');

  // page does not have secondary nav
  if (!secondaryNavBtn) return false;

  let secondaryNavList = document.querySelector('.a-sidebar__sidebar-nav');
  let menuIcon = secondaryNavBtn.querySelector('.a-sidebar__mobile--icon');
  let secondaryNavContent = document.querySelector('.a-sidebar__sidebar-wrapper');
  let sideBarDivider = document.querySelector('.a-sidebar__mobile--divider');

  secondaryNavBtn.addEventListener('click', () => {
    // close nav
    if (isSecondaryMenuOpen) {
      anime.set(secondaryNavContent, { opacity: 0 });
      sideBarDivider.style.display = 'none';

      // animate menu toggle
      anime({
        targets: secondaryNavList,
        height: 0,
        duration: 400,
        easing: 'easeOutSine',
        complete: () => {
          secondaryNavList.removeAttribute('style');
        }
      });

      // animate menu icon
      anime({
        targets: menuIcon.children[1],
        rotate: 0,
        top: 13,
        duration: 200,
        easing: 'easeOutSine'
      });
      anime({
        targets: menuIcon.children[2],
        rotate: 0,
        bottom: 15,
        duration: 200,
        easing: 'easeOutSine',
        complete: () => {
          anime({
            targets: menuIcon.children[0],
            translateX: 0,
            duration: 200,
            easing: 'easeOutSine'
          });
        }
      });
    } else {
      // get menu height
      secondaryNavList.style.display = 'block';
      let height = window.getComputedStyle(secondaryNavList).getPropertyValue('height');
      secondaryNavList.style.height = 0;
      sideBarDivider.style.display = 'block';

      // animate menu toggle
      anime({
        targets: secondaryNavList,
        height: height,
        duration: 400,
        easing: 'easeOutSine'
      });

      anime({
        targets: secondaryNavContent,
        opacity: [0, 1],
        duration: 400,
        easing: 'easeOutSine'
      });

      // animate menu icon
      anime({
        targets: menuIcon.children[0],
        translateX: -25,
        duration: 200,
        easing: 'easeOutSine',
        complete: () => {
          anime({
            targets: menuIcon.children[1],
            rotate: 45,
            top: matchMediaBelowSmall.matches ? 14 : 13,
            duration: 200,
            easing: 'easeOutSine'
          });
          anime({
            targets: menuIcon.children[2],
            rotate: -45,
            bottom: matchMediaBelowSmall.matches ? 17 : 15,
            duration: 200,
            easing: 'easeOutSine'
          });
        }
      });
    }

    // toggle open flag
    isSecondaryMenuOpen = !isSecondaryMenuOpen;
  });
}

// show / hide search icon inside input
function searchInputMainMenu() {
  let input = document.querySelector('.a-nav__input');
  let inputMobile = document.querySelector('.a-nav__mobile-search-input');
  let image = window.getComputedStyle(input).getPropertyValue('background-image');

  input.addEventListener('input', e => {
    if (e.target.value.length > 0) {
      e.target.style.backgroundImage = 'none';
    } else {
      e.target.style.backgroundImage = image;
    }
  });

  inputMobile.addEventListener('input', e => {
    if (e.target.value.length > 0) {
      e.target.style.backgroundImage = 'none';
    } else {
      e.target.style.backgroundImage = image;
    }
  });
}

function scrollBodyProgress() {
  let bodyEl = document.getElementsByTagName('body')[0];
  let controller = new ScrollMagic.Controller();

  let watchScroll = new ScrollMagic.Scene({
    duration: bodyEl.getBoundingClientRect().height - window.innerHeight,
    offset: 0
  })
    // .addIndicators()
    .addTo(controller);

  watchScroll.on('progress', event => {
    if (event.progress === 0 && isHomepage) {
      setTimeout(() => {
        document.getElementsByClassName('m-banner')[0].style.opacity = 1;
        navigation.querySelector('.a-nav--light-bg').style.opacity = 0;
        navigation.classList.remove('a-nav--light');
      }, 0);
    }

    if (event.progress > 0.15) {
      if (sectionTransaction) {
        sectionTransaction.style.top = 0;
      }
    } else {
      if (sectionTransaction) {
        sectionTransaction.style.top = 'auto';
      }
    }

    if (event.progress === 1 && isHomepage) {
      document.getElementsByClassName('m-banner')[0].style.opacity = 0;
    }
  });
}

function scrollNavState() {
  // if below breakpoint, do not run
  if (matchMediaBelowLarge.matches) return false;

  if (!isHomepage) {
    navigation.querySelector('.a-nav--light-bg').style.opacity = 1;
  }

  // scrollMagic
  let controller = new ScrollMagic.Controller();
  let navScene = new ScrollMagic.Scene({
    duration: bannerEl.offsetHeight - bannerEl.offsetHeight / 4,
    offset: 1
  })
    // .addIndicators()
    .addTo(controller);

  // animejs
  let navTL = anime.timeline({
    easing: 'easeOutSine',
    duration: navScene.duration(),
    autoplay: false
  });

  navTL.add({
    targets: navigation,
    height: [107, 90]
  });

  navScene.on('progress', event => {
    navTL.seek(navScene.duration() * event.progress);

    if (event.progress > 0.5) {
      navigation.classList.add('a-nav--light');

      // start after .5, but count from 1 to 0
      if (navigation.classList.contains('a-nav--homepage')) {
        document.getElementsByClassName('m-banner')[0].style.opacity = (1 - event.progress + 0.5) / 0.5 - 1;
        document.querySelector('.m-choice__arrow').style.display = 'none';
      }
    }

    if (event.progress == 0) {
      if (isHomepage && navigation.classList.contains('a-nav--light')) {
        setTimeout(() => {
          document.querySelector('.m-choice__arrow').style.display = 'block';
          navigation.classList.remove('a-nav--light');
        }, 0);
      }
    }
  });

  responsiveScenes.addScene(navScene, matchMedia('(max-width: 1350px)'), () => navTL.seek(0));
}

function scrollNavStateMobile() {
  // if above breakpoint, do not run
  if (!matchMediaBelowLarge.matches) return false;

  // scrollMagic
  let controller = new ScrollMagic.Controller();
  let navScene = new ScrollMagic.Scene({
    duration: bannerEl.offsetHeight - bannerEl.offsetHeight / 4,
    offset: 1
  })
    // .addIndicators()
    .addTo(controller);

  navScene.on('progress', event => {
    if (event.progress == 0) {
      if (isHomepage && navigation.classList.contains('a-nav--light')) {
        setTimeout(() => {
          navigation.classList.remove('a-nav--light');
        }, 0);
      }
    }
  });

  navScene.on('end', event => {
    if (isHomepage) {
      if (event.scrollDirection === 'FORWARD') {
        navigation.querySelector('.a-nav--light-bg').style.backgroundColor = '#fff';
        navigation.querySelector('.a-nav__mobile').style.backgroundColor = '#fff';
        navigation.querySelector('.a-nav__mobile--right').style.backgroundColor = 'rgba(236, 242, 247, .5)';
        navigation.querySelector('.a-nav__mobile--text').style.color = '#4b596d';
        navigation.style.backgroundColor = 'transparent';

        let paths = navigation.querySelectorAll('.a-nav__logo svg .st1');
        [...paths].forEach(el => {
          el.style.fill = '#4b596d';
        });
      } else {
        navigation.querySelector('.a-nav--light-bg').style.backgroundColor = 'transparent';
        navigation.querySelector('.a-nav__mobile').style.backgroundColor = 'transparent';
        navigation.querySelector('.a-nav__mobile--right').style.backgroundColor = 'transparent';
        navigation.querySelector('.a-nav__mobile--text').style.color = '#fff';
        navigation.style.backgroundColor = 'transparent';

        let paths = navigation.querySelectorAll('.a-nav__logo svg .st1');
        [...paths].forEach(el => {
          el.style.fill = '#fff';
        });
      }
    }
  });
}

function scrollLogo() {
  // if below breakpoint, do not run
  if (matchMediaBelowLarge.matches) return false;

  let svgTextEl = navigation.querySelector('.logo_text');
  let bgEl = navigation.querySelector('.a-nav--light-bg');
  let svgLogoEl = navigation.querySelector('.a-nav__logo');
  let svgDividerEl = navigation.querySelector('.logo_divider');
  let isHomepage = navigation.classList.contains('a-nav--homepage');

  // scrollMagic
  let controller = new ScrollMagic.Controller();
  let logoScene = new ScrollMagic.Scene({
    duration: bannerEl.offsetHeight - bannerEl.offsetHeight / 4,
    offset: 1
  })
    // .addIndicators()
    .addTo(controller);

  // animejs
  let logoTL = anime.timeline({
    easing: 'easeOutSine',
    duration: logoScene.duration(),
    autoplay: false
  });

  logoTL.add({
    targets: svgLogoEl,
    top: [54, 30],
    left: [58, 35]
  });

  logoScene.on('progress', event => {
    // hp does not start w/ the navbar white
    // if hp, fade in white background
    if (isHomepage) {
      bgEl.style.opacity = event.progress;
    }

    svgTextEl.style.opacity = 1 - event.progress * 2;
    svgDividerEl.style.opacity = 1 - event.progress * 2;
    logoTL.seek(logoScene.duration() * event.progress);
  });

  window.addEventListener('resize', () => {
    if (matchMediaBelowLarge.matches) {
      svgLogoEl.style.left = '0px';
    } else {
      svgLogoEl.style.left = '58px';
    }
  });

  responsiveScenes.addScene(logoScene, matchMedia('(max-width: 1350px)'), () => logoTL.seek(0));
}

// scroll to top link in footer
function scrollToPageTop() {
  let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
  const scrollTopTrigger = document.querySelector('.a-footer__to-top');

  scrollTopTrigger.addEventListener('click', () => {
    anime({
      targets: scrollElement,
      scrollTop: 0,
      duration: 1250,
      easing: 'easeInOutQuart'
    });
  });
}

window.addEventListener('resize', () => {
  // check if width has changed
  if (winWidth !== window.innerWidth) {
    // reset menu / styles
    document.body.style.overflow = 'auto';
    isMainMenuOpen = false;
    mainMenuText.style.display = 'block';
    mainNavBtnContainer.style.backgroundColor = '#ecf2f7';
    mainNavBtnContainer.style.backgroundColor = 'rgba(236, 242, 247, .5)';
    mainNavBtnContainer.style.border = 'none';
    mainMenuText.textContent = 'menu';

    // reset menu icon animation
    anime.set(mainMenuIcon.children[0], { rotate: 0, top: matchMediaBelowXLarge.matches ? 15 : 16, left: 0 });
    anime.set(mainMenuIcon.children[2], { rotate: 0, bottom: matchMediaBelowXLarge.matches ? 14 : 16, left: 0 });
    anime.set(mainMenuIcon.children[1], { translateX: 0, opacity: 1 });
    $('.menu-open-vjs').removeAttr('style');

    // apply correct logo / menu height depending on
    // window width and if user has triggered miniMenu
    if (matchMediaBelowLarge.matches) {
      if (!navigation.classList.contains('a-nav--homepage')) {
        navigation.style.backgroundColor = '#fff';
      }
      navigation.style.height = '60px';
      logoContainer.style.top = '16px';

      // reset mobile menu styles
      $('.a-nav__list--mobile-util').removeAttr('style');
    } else {
      if (miniMenu) {
        navigation.style.height = `${navHeightMobile}px`;
        logoContainer.style.top = `${navLogoTopMobile}px`;
      } else {
        navigation.style.height = '107px';

        logoContainer.style.top = '30px';
        // if(window.scrollY === 0) {
        // } else {
        //   logoContainer.style.top = '54px';
        // }

        if (isHomepage) {
          scrollNavState();
        }
      }
    }

    // if secondary nav it opened, then closed then
    // the browser is resized, make sure the
    // opacity of of the inner content is 1
    if (!matchMediaBelowMenuSecondary.matches) {
      let sidebarWrap = document.querySelector('.a-sidebar__sidebar-wrapper');
      if (sidebarWrap) sidebarWrap.style.opacity = 1;
    }
  }
});

function titleFWCKerning() {
  let kernEl = document.querySelector('.m-conference__content--title .beta');

  // add class that has negative margin-left
  // to pull 'C' closer to 'FW'.
  if (kernEl && kernEl.textContent.indexOf('FWC') >= 0) {
    kernEl.innerHTML = `FW<span class="m-conference__content--kern">C</span>`;
  }
}

function customEventPolyfill() {
  if ( typeof window.CustomEvent === "function" ) return false;

  function CustomEvent ( event, params ) {
      params = params || { bubbles: false, cancelable: false, detail: undefined };
      var evt = document.createEvent('CustomEvent');
      evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
      return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
}

document.addEventListener('DOMContentLoaded', () => {

  titleFWCKerning();
  toggleMainMenu();
  openMainSubMenu();
  toggleSecondaryMenu();
  toggleSecondarySubMenu();
  toggleFilterButtons();
  searchInputMainMenu();
  scrollToPageTop();
  scrollNavState();
  scrollLogo();
  scrollBodyProgress();
  objectFitImages();
  objectFitVideos();
  svg4everybody();
  toast();

  const sortingContainer = document.querySelector('.js-sorting');
  if(sortingContainer){
    sortingControls(sortingContainer);
  }
});