const toast = () => {
  const header = document.querySelector('header.a-nav');
  const toast = document.querySelector('.m-toast--top');
  if (!toast) return;

  let toastHeight = toast.getBoundingClientRect().height;
  window.addEventListener('resize', () => toastHeight = toast.getBoundingClientRect().height);
  window.addEventListener('load', () => toastHeight = toast.getBoundingClientRect().height);

  let isToastOpen = true;
  let isHeaderSticky = false;

  function setHeaderTop() {
    if (isHeaderSticky || !isToastOpen) return;
    header.style.top = `${toastHeight}px`;
  }
  setHeaderTop();
  window.addEventListener('resize', setHeaderTop);
  window.addEventListener('load', setHeaderTop);

  function makeHeaderSticky() {
    if ((window.pageYOffset >= toastHeight || !isToastOpen) && !isHeaderSticky) {
      isHeaderSticky = true;
      header.style.position = 'fixed';
      header.style.top = 0;
    } else if ((window.pageYOffset < toastHeight && isToastOpen) && isHeaderSticky) {
      isHeaderSticky = false;
      header.style.position = 'absolute';
      header.style.top = `${toastHeight}px`;
    }
  }
  makeHeaderSticky();
  window.addEventListener('scroll', makeHeaderSticky);
  window.addEventListener('load', makeHeaderSticky);


  const triangles = document.querySelectorAll('.m-banner__triangles--left, .m-banner__triangles--right');
  const adjustTriangles = () => {
    if (!isToastOpen) return;
    [...triangles].forEach(t => t.style.bottom = `${0 - toastHeight}px`);
  };
  if (triangles.length) {
    adjustTriangles();
    window.addEventListener('resize', adjustTriangles);
    window.addEventListener('load', adjustTriangles);
  }

  const toastClose = toast.querySelector('.m-toast__close');
  const closeToast = () => {
    isToastOpen = false;
    isHeaderSticky = true;
    header.style.position = 'fixed';
    header.style.top = 0;
    toast.style.display = 'none';

    if (triangles.length) {
      [...triangles].forEach(t => t.style.bottom = 0);
    }

    localStorage.setItem(toast.dataset.toastId, 'closed');
  };

  toastClose.addEventListener('click', closeToast);
  if (localStorage.getItem(toast.dataset.toastId) === 'closed') {
    closeToast();
  }
}

export default toast;