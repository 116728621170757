class dropDown {
  constructor(dropdown) {
    this.dropdown = dropdown;
  }

  selectUpdate() {
    if(this.dropdown !== null) {
      const state = this.dropdown.value;
      const stateInfoSection = document.querySelectorAll('.m-chapter-locator__state');

      let selected = [...stateInfoSection].filter(el => {
        // remove active class
        el.classList.remove('m-chapter-locator__state--active');
        return el.dataset.state == state;
      });

      // add active class
      if(selected[0]) {
        selected[0].classList.add('m-chapter-locator__state--active');
      }
    }
  }

  init() {
    this.dropdown.addEventListener('change', () => this.selectUpdate());
  }
}

window.addEventListener('load', () => {
  const dropdowns = document.querySelectorAll('select');

  [...dropdowns].forEach(dropdown => {
    const dd = new dropDown(dropdown);
    dd.init();
  });
});
